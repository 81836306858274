<template>
  <div>
    <router-link to="/admin/point/withdraw">
      <b-button class="btn btn-gradient-primary mb-1 mr-50 col-md-2">
        {{ $t('returnToList') }}
      </b-button>
    </router-link>
    <b-button
      v-if="items.status_okay == 1"
      variant="success"
      class="mb-1 ml-25 mr-50 col-md-2"
      @click="Submit()"
    >
      {{ $t('approve') }}
    </b-button>
    <b-button
      v-if="items.status_okay== 1"
      variant="danger"
      class="mb-1 ml-25 mr-50 col-md-2"
      @click="cancelstatus()"
    >
      {{ $t('cancelOrder') }}
    </b-button>
    <b-row>
      <b-col md="6">
        <div class="card">
          <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
            <h3 class="mb-0">
              {{ $t('details') }}

            </h3>
          </div>

          <div class="p-2">
            <b-col md="12">
              <div class="align-items-center text-left mb-n2">
                <b-form-group
                  label-cols="6"
                  label-cols-md="6"
                  label-cols-lg="6"
                >
                  <template v-slot:label>
                    {{ $t('orderNumber') }} :
                  </template>
                  <p class="mt-50">
                    {{ items.itemnumber_text }}
                  </p>

                </b-form-group>
              </div>
              <div class="align-items-center text-left mb-n2">
                <b-form-group
                  label-cols="6"
                  label-cols-md="6"
                  label-cols-lg="6"
                >
                  <template v-slot:label>
                    {{ $t('customerCode') }} :
                  </template>
                  <p class="mt-50">
                    {{ items.username }}
                  </p>

                </b-form-group>
              </div>
              <div class="align-items-center text-left mb-n2">
                <b-form-group
                  label-cols="6"
                  label-cols-md="6"
                  label-cols-lg="6"
                >
                  <template v-slot:label>
                    {{ $t('status') }} :
                  </template>
                  <p
                    v-if="items.status_okay == 1"
                    class="mt-50 badge badge-light-danger col-md-4"
                  >
                    {{ $t('pendingReview') }}

                  </p>
                  <p
                    v-if="items.status_okay == 2"
                    class="mt-50 badge badge-light-success col-md-4"
                  >
                    {{ $t('operationCompleted') }}
                  </p>
                  <p v-if="items.status_okay == 3">
                    <span class="mt-50 badge badge-light-success col-md-4">{{ $t('cancelled') }}</span>
                    <span class="text-danger">* {{ $t('canceledBy') }} {{ items.by_cancel }}</span>
                  </p>

                </b-form-group>
              </div>
              <div class="align-items-center text-left mb-n2">
                <b-form-group
                  label-cols="6"
                  label-cols-md="6"
                  label-cols-lg="6"
                >
                  <template v-slot:label>
                    {{ $t('quantity') }} :
                  </template>
                  <p class="mt-50">
                    {{ Commas(items.amount) }} p
                  </p>

                </b-form-group>
              </div>
              <div class="align-items-center text-left mb-n2">
                <b-form-group
                  label-cols="6"
                  label-cols-md="6"
                  label-cols-lg="6"
                >
                  <template v-slot:label>
                    {{ $t('transactionDate') }} :
                  </template>
                  <p class="mt-50">
                    {{ time(items.created_at) }}
                  </p>

                </b-form-group>
              </div>

            </b-col>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="card">
          <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}

            </h3>
          </div>

          <div class="p-2">
            <b-col md="12">
              <table class="styled-table mt-n25">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('transactionDate') }}
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                    >
                      {{ $t('details') }}
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr
                    v-if="Transaction"
                    :key="index"
                    style="background: #fff; color: black;"
                  >
                    <th
                      class="text-center"
                      scope="col"
                    >
                      {{ time(Transaction.created_at) }}
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                    >
                      {{ Transaction.detail }}
                    </th>
                    <th
                      class="text-right text-danger"
                      scope="col"
                    >
                      - {{ Commas(Transaction.amount) }} ฿
                      <!-- <span :style="getAmountStyle(Transaction.type)">
                        {{ getAmountPrefix(Transaction.type) }}
                      </span> -->
                    </th>
                  </tr>
                  <tr
                    v-for="(item,index) in balance"
                    :key="index"
                    style="background: #fff; color: black;"
                  >
                    <th
                      class="text-center"
                      scope="col"
                    >
                      {{ time(item.created_at) }}
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                    >
                      {{ item.details }}
                    </th>
                    <th
                      class="text-right text-danger"
                      scope="col"
                    >
                      <span :style="getAmountStyle(item.type)">
                        {{ getAmountPrefix(item.type) }} {{ Commas(item.amount) }} p
                      </span>
                    </th>
                  </tr>
                </thead>
              </table>
            </b-col>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  // BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import moment from 'moment-timezone'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // ValidationProvider,
    ValidationObserver,
    // BFormInput,
    BFormGroup,
    // BFormTextarea,
    // BTable,
    BFormCheckbox,

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      ID: this.$route.params.id,
      items: [],
      balance: [],
      Transaction: [],
      status: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getData(this.status)
    this.getPoint()
    this.getTransaction()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    async getData(type) {
      try {
        const params = {
          ID: this.ID,
          type,
        }
        const { data: res } = await this.$http.get('/wpoints/getDataOne', { params })
        this.items = res
      } catch (e) {
        console.log(e)
      }
    },
    async getPoint() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/wpoints/getpointin_w', { params })
        this.balance = res
      } catch (e) {
        console.log(e)
      }
    },
    async getTransaction() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/wpoints/getTransactionpoint', { params })
        this.Transaction = res
      } catch (e) {
        console.log(e)
      }
    },
    //
    Submit() {
      this.$bvModal.msgBoxConfirm('ต้องการอนุมัติ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            id: this.ID,
          }
          this.$http.post('/wpoints/StoreUpdate', obj)
            .then(() => {
              this.getData()
              this.Success(`อนุมัติรายการแลกแต้ม ${this.items.itemnumber_text} เรียบร้อย`)
            })
            .catch(err => {
              this.SwalError(err)
            })
        }
      })
    },
    cancelstatus() {
      this.$bvModal.msgBoxConfirm(`ยกเลิกรายการแลกแต้ม ${this.items.itemnumber_text}?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            id: this.ID,
          }
          this.$http.post('/wpoints/cancelwpoint', obj)
            .then(() => {
              this.getData()
              this.Success(`ยกเลิกรายการแลกแต้ม ${this.items.itemnumber_text} เรียบร้อย`)
            })
            .catch(err => {
              this.SwalError(err)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [16, 18].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(value)) {
          return '-'
        }
        if (value && [16, 18].includes(value)) {
          return '+'
        }
      }
      return ''
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
